/*-------------------------------------------------------------------
|  🐼 React FC NoWords / NotFound
|
|  🐯 Purpose: DISPLAYS BANNERS WHEN THERE IS NO WORD OR NO API DATA
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/

import Lottie from 'lottie-react'
import yellyEmojiNo from '../lotties/yelly-emoji-no.json'
import yellyEmojiYep from '../lotties/yelly-emoji-yep.json'
import { useDictionary } from '../context'
import { framer_opacity } from '../framer'
import { motion } from 'framer-motion'

const lottieStyles = {
  height: 150,
  width: 150,
}

export const Initial = () => {
  return (
    <Wrapper>
      {false ?? (
        <Lottie
          animationData={yellyEmojiYep}
          loop={true}
          style={lottieStyles}
        />
      )}
      <div className="flex flex-col items-center justify-center gap-2 text-center">
        <h1 className="text-xl font-bold">Rumca Türkçe Sözlük</h1>
        <p className="text-light-400 dark:text-gray-400 max-w-[30rem] m-auto">
          Rumca - Türkçe, Türkçe - Rumca Sözlük
        </p>
      </div>
    </Wrapper>
  )
}

export const NoWords = () => {
  return (
    <Wrapper>
      {false ?? (
        <Lottie
          animationData={yellyEmojiYep}
          loop={true}
          style={lottieStyles}
        />
      )}
      <div className="flex flex-col items-center justify-center gap-2 text-center">
        <h1 className="text-xl font-bold">Kelime Bulunamadı</h1>
        <p className="text-light-400 dark:text-gray-400 max-w-[30rem] m-auto">
          Aradığınız kelimeyi bulamadık
        </p>
      </div>
    </Wrapper>
  )
}

export const NotFound = () => {
  const { errorStatus } = useDictionary()
  const isServerError = errorStatus === 500
  return (
    <Wrapper>
      {false ?? (
        <Lottie animationData={yellyEmojiNo} loop={true} style={lottieStyles} />
      )}
      <h1 className="text-xl font-bold">
        {isServerError ? 'Sunucu Hatası' : 'Bulunamadı'}
      </h1>
      <p className="text-light-400 dark:text-gray-400 max-w-[30rem] m-auto">
        {isServerError ? (
          <span>Sunucu Hatası!!! Kısa sürede çözülecek lütfen bekleyiniz.</span>
        ) : (
          <span>Kusura bakmayın aradığınız kelime bulunamadı</span>
        )}
      </p>
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <motion.div {...framer_opacity} className="w-full px-5 custom_container">
      <div className="flex flex-col items-center justify-center w-full gap-2 px-5 py-10 text-center border-2 border-dashed dark:border-dark-100 md:py-12 rounded-xl">
        {children}
      </div>
    </motion.div>
  )
}
