/*-------------------------------------------------------------------
|  🐼 React FC Word
|
|  🐯 Purpose: DISPLAYS THE SEARCHED WORD'S TITLE AND INFORMATION
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/

import { Title, SubSection, ErrorFallback, NoWords } from './'
import { motion } from 'framer-motion'
import { framer_opacity } from '../framer'
import { ErrorBoundary } from 'react-error-boundary'

export const Word = ({ data }) => {
  console.log(data)
  return (
    <motion.div
      {...framer_opacity}
      className="flex flex-col gap-10 px-5 custom_container"
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {data.error ? (
          <NoWords key="noooo" />
        ) : (
          <>
            <Title data={data} />
            <SubSectionWrapper data={data} />
          </>
        )}
      </ErrorBoundary>
    </motion.div>
  )
}

const SubSectionWrapper = ({ data }) => {
  return (
    <>
      {data && data.meanings && data.meanings.length !== 0 ? (
        data.meanings.map((item, idx) => {
          return <SubSection key={item.partOfSpeech + idx} data={item} />
        })
      ) : (
        <></>
      )}
    </>
  )
}
