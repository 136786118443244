import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function Analytics() {
  useEffect(() => {
    function trackMousePosition(event) {
      const { clientX, clientY } = event
      window.gtag('event', 'mousemove', {
        event_category: 'Mouse',
        event_label: `X: ${clientX}, Y: ${clientY}`,
      })
    }

    document.addEventListener('mousemove', trackMousePosition)

    return () => {
      document.removeEventListener('mousemove', trackMousePosition)
    }
  }, [])

  return (
    <Helmet>
      <script
        src="https://www.googletagmanager.com/gtag/js?id=G-ZXEJDRFPCL"
        async
      />
      <script>
        {`
          if (!window.dataLayer) {
            window.dataLayer = [];
          }
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-ZXEJDRFPCL');
        `}
      </script>
    </Helmet>
  )
}
