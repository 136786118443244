/*-------------------------------------------------------------------
|  🐼 APP ROUTES
|
|  🐯 Purpose: AN ARRAY WHICH CONTAINS ALL THE APP'S ROUTES
|
|  🐸 Returns:  -
*-------------------------------------------------------------------*/

import { Home } from './index'

export const appRoutes = [
  {
    title: 'Homepage',
    Component: Home,
    path: '/',
  },
]
